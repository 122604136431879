<template>
    <div class="gpt-translate-index-page">
        <div class="project-type history" @click="$router.push('/agent/gptTranslate/history')">{{$t('tools.tools_gptTranslate_page_tips_1')}}</div>
        <div class="margin-y-12 fonts-14 color-666666 text-center">{{$t('tools.tools_gptTranslate_page_tips_2')}}</div>
        <div class="gpt-translate-wapper">
            <div class="gpt-translate-main">
                <div class="gpt-translate-content">
                    <div class="wapper-block">
                        <div class="bg-white radius-16">
                            <template v-if="step === 2">
                                <div class="token-info" v-if="taskTokenInfo">
                                    <div class="file-icon">
                                        <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + taskTokenInfo.iconType].icon}`"></svg-icon>
                                    </div>
                                    <div class="fonts-15 margin-t-10">{{taskTokenInfo.name}}</div>
                                    <template v-if="taskTokenInfo.parseComplete">
                                        <div class="fonts-15 margin-t-15">{{$t('tools.tools_gptTranslate_page_tips_3')}} <span class="color-success">{{taskTokenInfo.fileCount}}</span> {{$t('tools.tools_gptTranslate_page_tips_4')}}<span class="color-success">{{taskTokenInfo.wordCount}}</span> {{$t('tools.tools_gptTranslate_page_tips_5')}}</div>
                                        <div class="fonts-15 margin-t-15">{{$t('tools.tools_gptTranslate_page_tips_6')}} <span class="color-success">{{taskTokenInfo.tokenCount}}</span> {{$t('tools.tools_gptTranslate_page_tips_7')}}</div>
                                    </template>
                                    <template v-else>
                                        <div class="fonts-24 margin-t-10"><i class="el-icon-loading"></i></div>
                                        <div class="fonts-15 margin-t-10">{{$t('tools.tools_gptTranslate_page_tips_8')}}</div>
                                    </template>
                                </div>
                            </template>
                            <upload-oss-block ref="uploadNode" :drag="true" @change="uploadNodeChangeEvent" :multiple="true" :accept="acceptType" :limit="limitCount" :size="limitSize" v-show="step !== 2">
                                <div class="document-wrapper" v-if="step === 0">
                                    <svg-icon name="ib-upload-o" style="font-size: 50px;"></svg-icon>
                                    <!-- <div class="margin-t-10 fonts-15">大模型智能体翻译</div> -->
                                    <div class="margin-t-10 fonts-14 color-666666">{{$t('tools.tools_gptTranslate_page_tips_9')}}</div>
                                    <div class="margin-t-10 flex fonts-24">
                                        <span><svg-icon name="ic-word"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-excel"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-ppt"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-rtf"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-csv"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-txt"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-srt"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-sdlxliff"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-xliff"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-pdf"></svg-icon></span>
                                        <span class="margin-l-10"><svg-icon name="ic-zip"></svg-icon></span>
                                        <el-tooltip :content="uploadFileMessage" placement="top">
                                        <span class="margin-l-10 color-999"><svg-icon name="ib-question"></svg-icon></span>
                                        </el-tooltip>
                                    </div>
                                    <!-- <div class="text-center fonts-14 color-666666 margin-t-15 margin-b-15">
                                        <span class="margin-r-5">最多上传10个文件（单个文件最大20MB）</span>
                                        <el-tooltip :content="uploadFileMessage" placement="top">
                                            <svg-icon name="ib-question"></svg-icon>
                                        </el-tooltip>
                                    </div> -->
                                    <div class="margin-t-10 margin-b-20 fonts-14 color-666666">{{$t('tools.tools_gptTranslate_page_tips_10')}}<a class="line-underline margin-l-5" style="color: #1AC49C;" @click.stop href="/account/agentToken" target="_blank">{{$t('tools.tools_gptTranslate_page_tips_11')}}</a></div>
                                </div>
                                <div class="upload-file-list" v-else-if="step === 1">
                                    <template v-for="(file, index) in uploadFileList">
                                        <div class="file-item" :key="index">
                                            <div class="fonts-24 margin-r-5 flex-shrink-0">
                                                <svg-icon :name="'ic-'+file.fileType.icon"></svg-icon>
                                            </div>
                                            <div class="name">{{file.name}}</div>
                                            <div class="progress">
                                                <span class="fonts-16" style="color:#D7343F;" v-if="file.status==='fail'">
                                                    <svg-icon name="ib-error-o"></svg-icon>
                                                </span>
                                                <span class="fonts-16" style="color:#00B670;" v-else-if="file.status==='success'">
                                                    <svg-icon name="ib-check-circle"></svg-icon>
                                                </span>
                                                <span class="fonts-16" v-else>
                                                    <!-- {{file.percentage}}% -->
                                                        <i class="el-icon-loading"></i>
                                                </span>
                                            </div>
                                            <div class="delete-btn" @click.stop="deleteFileEvent(index)">
                                                <svg-icon name="ib-close" className="close-btn"></svg-icon>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </upload-oss-block>
                        </div>
                    </div>
                    <div class="flex flex-align-end flex-justify-between margin-t-20">
                        <div class="flex">
                            <div class="condition-item">
                                <div class="fonts-12 margin-b-10">{{$t('tools.tools_gptTranslate_page_tips_12')}}</div>
                                <el-select size="small" v-model="form.source" @change="langSelectChangeEvent" :disabled="step === 2" :placeholder="$t('tools.tools_gptTranslate_page_select_placeholder_1')" style="width:100%">
                                    <template v-for="item in sourceLanguageList">
                                        <el-option :label="item.name" :value="item.key" :key="item.key"></el-option>
                                    </template>
                                </el-select>
                            </div>
                            <div class="condition-item">
                                <div class="fonts-12 margin-b-10">{{$t('tools.tools_gptTranslate_page_tips_13')}}</div>
                                <el-select size="small" v-model="form.target" :disabled="step === 2" :placeholder="$t('tools.tools_gptTranslate_page_select_placeholder_1')" style="width:100%">
                                    <template v-for="item in targetLanguageList">
                                        <el-option :label="item.name" :value="item.key" :key="item.key" :disabled="item.key === form.source"></el-option>
                                    </template>
                                </el-select>
                            </div>
                            <div class="condition-item">
                                <div class="fonts-12 margin-b-10">{{$t('tools.tools_gptTranslate_page_tips_14')}}</div>
                                <div class="flex" v-if="termFiles.length > 0">
                                    <el-tooltip placement="top">
                                        <template slot="content">
                                            <template v-for="(item, index) in termFiles">
                                                <div class="line-height-1.5" :key="index">{{item.fileName}}</div>
                                            </template>
                                        </template>
                                        <el-button round plain size="small" class="text-omission" :disabled="step === 2" @click="uploadTermDialog.show = true">{{termFiles[0].fileName}}</el-button>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('tools.tools_gptTranslate_page_tips_15')" placement="top">
                                        <el-button round plain size="small" :disabled="step === 2" @click="termFiles = []" style="padding: 6px; font-size: 18px;">
                                            <svg-icon name="ib-trash-o-1"></svg-icon>
                                        </el-button>
                                    </el-tooltip>
                                </div>
                                <div v-else>
                                    <el-button round plain size="small" :disabled="step === 2" @click="uploadTermDialog.show = true">{{$t('tools.tools_gptTranslate_page_btn_1')}}</el-button>
                                </div>
                            </div>
                            <!-- 
                            <div class="condition-item">
                                <div class="fonts-12 margin-b-10">记忆库</div>
                                <div class="flex" v-if="memoryFiles.length > 0">
                                    <el-tooltip placement="top">
                                        <template slot="content">
                                            <template v-for="(item, index) in memoryFiles">
                                                <div class="line-height-1.5" :key="index">{{item.fileName}}</div>
                                            </template>
                                        </template>
                                        <el-button round plain size="small" class="text-omission" @click="uploadMemoryDialog.show = true">{{memoryFiles[0].fileName}}</el-button>
                                    </el-tooltip>
                                    <el-tooltip content="清除记忆库" placement="top">
                                        <el-button round plain size="small" @click="memoryFiles = []" style="padding: 6px; font-size: 18px;">
                                            <svg-icon name="ib-trash-o-1"></svg-icon>
                                        </el-button>
                                    </el-tooltip>
                                </div>
                                <div v-else>
                                    <el-button round plain size="small" @click="uploadMemoryDialog.show = true">上传记忆库</el-button>
                                </div>
                            </div>
                            -->
                        </div>
                        <div v-if="step === 1">
                            <el-button round plain size="small" @click="continueUploadEvent">{{$t('tools.tools_gptTranslate_page_btn_2')}}</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center" style="width: 900px; margin: 15px auto 0 auto;">
            <template v-if="step === 0">
                <div style="height:35.2px"></div>
                <!-- <el-button round type="primary" size="medium" :loading="btnLoading" @click="nextBtnEvent" >下一步</el-button> -->
            </template>
            <template v-else-if="step === 1">
                <el-button plain round size="medium" @click="step = 0; uploadFileList = []; $refs.uploadNode && $refs.uploadNode.reset();">{{$t('tools.tools_gptTranslate_page_btn_3')}}</el-button>
                <el-button round type="primary" size="medium" :loading="btnLoading" :disabled="nextBtnDisabled" @click="nextBtnEvent">{{$t('tools.tools_gptTranslate_page_btn_4')}}</el-button>
            </template>
            <template v-else-if="step === 2">
                <template v-if="taskTokenInfo.parseComplete">
                    <el-button plain round size="medium" @click="step = 1;">{{$t('tools.tools_gptTranslate_page_btn_3')}}</el-button>
                    <el-button round type="primary" size="medium" :loading="btnLoading" @click="startTranslateTask">{{$t('tools.tools_gptTranslate_page_btn_5')}}</el-button>
                </template>
            </template>
        </div>
        <template v-if="taskInfo">
            <!-- <div class="text-center" style="width: 588px; margin: 15px auto 0 auto;">
                <el-divider class="divider-style"></el-divider>
            </div> -->
            <!-- <div style="width: 900px; margin: 30px auto 0 auto;">
                <div class="project-type current">当前项目</div>
                <div class="project-type history margin-l-10" @click="$router.push('/agent/gptTranslate/history')">项目历史</div>
            </div> -->
            <div class="current-task-info" style="width: 900px; margin: 30px auto 0 auto;">
                <svg-icon name="ic-word" className="fonts-24 flex-shrink-0"></svg-icon>
                <div class="fonts-15 margin-l-10 text-omission" style="max-width: 210px;">{{taskInfo.name}}</div>
                <div class="fonts-12 margin-l-30 flex-shrink-0 flex" style="flex-shrink: 0;">
                    <language-direction :source="taskInfo.sourceLang" :target="taskInfo.targetLang" type="half"></language-direction>
                    <el-divider direction="vertical"></el-divider>
                    <div>{{$t('tools.tools_gptTranslate_page_tips_16', {size: taskInfo.fileCount || '-'})}}</div>
                    <el-divider direction="vertical"></el-divider>
                    <div>{{$t('tools.tools_gptTranslate_page_tips_17', {size: taskInfo.wordCount || '-'})}}</div>
                </div>
                <div class="fonts-12 margin-l-30 flex-shrink-0 flex flex-align-center">
                    <div>{{$t('tools.tools_gptTranslate_page_tips_18')}}</div>
                    <div class="margin-l-10" style="width: 102px" v-if="!isTokensLack">
                        <el-progress :percentage="taskInfo.process || 0" :show-text="false" color="#00B670" define-back-color="#fff" :stroke-width="4"></el-progress>
                    </div>
                </div>
                <template v-if="taskInfo.status === 'S'">
                    <div class="fonts-12 margin-l-15 flex flex-align-center">
                        <svg-icon name="ib-check-circle" className="color-1AC49C fonts-15 margin-r-5"></svg-icon> {{taskInfo.statusDesc}}
                        <svg-icon name="ib-download-1" className="fonts-15 margin-l-30"></svg-icon>
                        <el-divider direction="vertical"></el-divider>
                        <div @click="downloadFile(taskInfo, 'target')" class="cursor-pointer color-link">{{$t('tools.tools_gptTranslate_page_tips_19')}}</div>
                        <div @click="downloadFile(taskInfo, 'bilingual')" class="cursor-pointer color-link margin-l-7">{{$t('tools.tools_gptTranslate_page_tips_20')}}</div>
                        <div @click="deleteTask(taskInfo)" class="cursor-pointer color-F56C6C margin-l-7">{{$t('tools.tools_gptTranslate_page_tips_21')}}</div>
                    </div>

                </template>
                <template v-else-if="taskInfo.status === 'F'">
                    <div class="fonts-12 margin-l-15 flex flex-align-center">
                        <svg-icon name="ib-error-o" className="color-F56C6C fonts-15 margin-r-5"></svg-icon> {{taskInfo.statusDesc}}
                        <el-divider direction="vertical"></el-divider>
                        <div @click="handleReset" class="cursor-pointer color-link margin-l-10">{{$t('tools.tools_gptTranslate_page_tips_22')}}</div>
                        <div @click="deleteTask(taskInfo)" class="cursor-pointer color-F56C6C margin-l-10">{{$t('tools.tools_gptTranslate_page_tips_21')}}</div>
                    </div>
                </template>
                <template v-else-if="isTokensLack">
                    <div class="fonts-12 margin-l-10 flex flex-align-center">
                        <svg-icon name="ib-info" className="color-F56C6C fonts-15 margin-r-5"></svg-icon> {{taskInfo.failureCause}}
                        <el-divider direction="vertical"></el-divider>
                        <div @click="$router.replace('/account/agentToken')" class="cursor-pointer color-link">{{$t('tools.tools_gptTranslate_page_tips_23')}}</div>
                        <div @click="handleReset" class="cursor-pointer color-link margin-l-10">{{$t('tools.tools_gptTranslate_page_tips_22')}}</div>
                        <div @click="deleteTask(taskInfo)" class="cursor-pointer color-F56C6C margin-l-10">{{$t('tools.tools_gptTranslate_page_tips_21')}}</div>
                    </div>
                </template>
               <template v-else-if="taskInfo.status === 'TP'">
                    <div class="fonts-12 margin-l-15 flex flex-align-center">
                        <svg-icon name="ib-error-o" className="color-F56C6C fonts-15 margin-r-5"></svg-icon> {{taskInfo.statusDesc}}
                        <el-divider direction="vertical"></el-divider>
                        <div @click="handleReset" class="cursor-pointer color-link margin-l-10">{{$t('tools.tools_gptTranslate_page_tips_22')}}</div>
                        <div @click="deleteTask(taskInfo)" class="cursor-pointer color-F56C6C margin-l-10">{{$t('tools.tools_gptTranslate_page_tips_21')}}</div>
                    </div>
                </template>
                <template v-else>
                    <div class="fonts-12 margin-l-15 text-omission" v-if="taskInfo.current.fileName">
                        {{$t('tools.tools_gptTranslate_page_tips_24')}}<span class="color-success">{{taskInfo.current.fileName}}</span>
                    </div>
                </template>
            </div>
            <template v-if="taskInfo.status === 'TP'">
                <div class="text-center" style="width: 863px; margin: 15px auto 0 auto;">
                    <el-divider class="divider-style"></el-divider>
                </div>
            </template>
            <template v-else>
                <div class="current-task-steps" style="width: 900px; margin: 30px auto 0 auto;">
                    <el-steps align-center>
                        <template v-for="(step, index) in taskInfo.current.stageList">
                            <el-step :title="step.text" :status="step.current ? 'process' : ''" :key="index"></el-step>
                        </template>
                    </el-steps>
                </div>
                <div class="current-task-logs">
                    <div class="logs-content">
                        <template v-for="(log, index) in taskInfo.logs">
                            <p :key="index">{{log.msg}}</p>
                        </template>
                    </div>
                
                </div>
            </template>
            <!-- <div class="text-center" style="width: 900px; margin: 30px auto 0 auto;" v-if="taskInfo.status === 'S'">
                <el-dropdown @command="command => downloadFile(taskInfo, command)">
                    <el-button type="primary" round size="medium">下载</el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="target">下载译文</el-dropdown-item>
                        <el-dropdown-item command="bilingual">下载双语</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div> -->
        </template>
        
        <el-dialog :title="$t('tools.tools_gptTranslate_page_dialog_1_title')" center :visible.sync="uploadTermDialog.show" width="520px" top="20px" @close="handleUploadTermDialogClose">
            <div>
                <div class="fonts-12 margin-b-16 line-height-0 text-center">
                    {{$t('tools.tools_gptTranslate_page_dialog_1_tips_1', {typs: 'xlsx/xls/tbx/sdltb/txt', size: uploadTermDialog.limitSize})}}
                    <el-button type="text" class="color-link padding-0" :disabled="uploadTermDialog.templateLoading" @click="downloadTemplateEvent('excel')">excel</el-button>
                    <el-button type="text" class="color-link padding-0" :disabled="uploadTermDialog.templateLoading" @click="downloadTemplateEvent('tbx')">tbx</el-button>
                </div>
                <upload-oss :multiple="true" ref="uploadTermDom" accept=".xls,.xlsx,.tbx,.sdltb,.txt" :limit="uploadTermDialog.limitCount" :size="uploadTermDialog.limitSize" :params="{}"></upload-oss>
            </div>
            <div slot="footer">
                <el-button round plain size="small" @click="uploadTermDialog.show = false">{{$t('tools.tools_gptTranslate_page_dialog_1_btn_1')}}</el-button>
                <el-button round type="primary" size="small" style="margin-left:30px;" @click="saveUploadTermEvent">{{$t('tools.tools_gptTranslate_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('tools.tools_gptTranslate_page_dialog_2_title')" center :visible.sync="uploadMemoryDialog.show" width="520px" top="20px" @close="handleUploadMemoryDialogClose">
            <div>
                <div class="fonts-12 margin-b-16 line-height-0 text-center">
                    {{$t('tools.tools_gptTranslate_page_dialog_2_tips_1', {typs: 'xlsx/xls/tmx/sdltm', size: uploadMemoryDialog.limitSize})}}
                    <el-button type="text" class="color-link padding-0" :disabled="uploadMemoryDialog.templateLoading" @click="downloadMemoryTemplateEvent('excel')">excel</el-button>
                    <el-button type="text" class="color-link padding-0" :disabled="uploadMemoryDialog.templateLoading" @click="downloadMemoryTemplateEvent('tmx')">tmx</el-button>
                </div>
                <upload-oss :multiple="true" ref="uploadMemoryDom" accept=".xls,.xlsx,.tmx,.sdltm" :limit="uploadMemoryDialog.limitCount" :size="uploadMemoryDialog.limitSize" :params="{}"></upload-oss>
            </div>
            <div slot="footer">
                <el-button round plain size="small" @click="uploadMemoryDialog.show = false">{{$t('tools.tools_gptTranslate_page_dialog_2_btn_1')}}</el-button>
                <el-button round type="primary" size="small" style="margin-left:30px;" @click="saveUploadMemoryEvent">{{$t('tools.tools_gptTranslate_page_dialog_2_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" :message="alertInfo.message" :type="alertInfo.type" :buttonType="alertInfo.buttonType" :cancelButtonName="alertInfo.cancelButtonName" @confirmEvent="alertInfo.confirmEvent"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import tools from '@/utils/tools';
import config from '@/utils/config';
import { toolsApi, docApi, commonApi, wsApi } from '@/utils/api';
import UploadOss from '@/components/UploadOss';
import UploadOssButton from '@/components/UploadOssButton';
import UploadOssBlock from '@/components/UploadOssBlock';
import AlertDialog from '@/components/AlertDialog';
import fileDownload from 'js-file-download';
import languageDirection from '@/components/LanguageDirection';
import IWebSocket from '@/utils/websocket';
export default {
    components: { UploadOssButton, AlertDialog, languageDirection, UploadOss, UploadOssBlock, },
    data (){
        return {
            config,
            tools,
            acceptType: `.docx,.doc,.rtf,.xlsx,.xls,.ppt,.pptx,.srt,.txt,.csv,.xliff,.xlf,.sdlxliff,.sdlxlf,.pdf,.zip,.rar`,
			uploadFileMessage: this.$t('tools.tools_gptTranslate_page_data_uploadFileMessage', {type: 'docx/doc/rtf/xlsx/xls/ppt/pptx/srt/txt/csv/xliff/xlf/sdlxliff/sdlxlf/pdf/zip/rar'}),
            form: {
                source: 'zh',
                target: 'en',
            },
            termFiles: [],
            memoryFiles: [],
            listVisible: true,
            refreshLoading: false,
            list: [],
            total: 0,
            pageNumber: 1,
            pageSize: 6,
            sourceLanguageList: [],
            targetLanguageList: [],
            taskTimer: null,
            sizeTimer: null,
            docs: [],
            limitSize: 500,
            limitCount: 200,
            btnLoading: false,
            nextBtnDisabled: false,
            taskId: null,
            previewDialog: {
                show: false,
                loading: false,
                param: {},
            },
            uploadTermDialog: {
                show: false,
                templateLoading: false,
                limitSize: 100,
                limitCount: 10,
            },
            uploadMemoryDialog: {
                show: false,
                templateLoading: false,
                limitSize: 100,
                limitCount: 10,
            },
            alertInfo: {
                message: '',
                type: 'confirm',
                cancelButtonName: this.$t('tools.tools_gptTranslate_page_data_alertInfo_cancelButtonName'),
                buttonType: 'primary',
                confirmEvent: ()=>{},
            },
            step: 0, // 0: 上传文件，1: 信息确认，2：提交任务
            uploadFileList: [],
            taskTokenInfo: null,
            taskId: null,
            taskInfo: null,
            socket: null,
            isTokensLack: false,
            isReset: false,
        }
    },
    computed: {
        ...mapState({

        }),
    },
    created(){
        this.initLanguageList();
    },
    methods: {

        uploadNodeChangeEvent (fileList) {
            console.log(fileList);
            this.uploadFileList = fileList;
            let notSuccessFiles = this.uploadFileList.filter(item => item.status !== 'success' && item.status !== 'fail');
            this.nextBtnDisabled = false;
            if(notSuccessFiles.length > 0){
                this.nextBtnDisabled = true;
            }
            if(this.uploadFileList.length > 0){
                this.step = 1;
            }else {
                this.step = 0;
            }
        },
        deleteFileEvent(index) {
            this.$refs.uploadNode.deleteFileEvent(index);
        },
        continueUploadEvent () {
            this.$refs.uploadNode.$el.querySelector('input[type="file"]').click();
        },
        nextBtnEvent () {
            if(this.step === 0){
                if(this.uploadFileList.length === 0){
                    this.$message.error(this.$t('tools.tools_gptTranslate_page_method_nextBtnEvent_message_1'));
                }
            }else if(this.step === 1) {
                this.createTask();
            }
        },
        async initLanguageList(){
            let url = `${toolsApi.queryGPTTranslateLanguageOptions}?engine=azure-gpt`;
            const res = await this.$ajax.get(url);
            if(res.status === 200){
                this.sourceLanguageList = res.data.sourceLanguages;
                this.targetLanguageList = res.data.targetLanguages;
            }
        },
        startTranslateTask() {
            let url = `${toolsApi.startGPTTranslateTask}?taskId=${this.taskId}`;
            this.btnLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.step = 0;
                    this.closeUploadDocument();
                    if(this.$refs.uploadNode){
                        this.$refs.uploadNode.reset();
                    }
                    this.termFiles = [];
                    this.memoryFiles = [];
                    this.uploadFileList = [];
                    this.initTaskList();
                }
            }).finally(()=>{
                this.btnLoading = false;
            })
        },
        initTaskList(){
            this.isTokensLack = false;
            let url = `${toolsApi.queryGPTTranslateTaskStatus}`;
            if(this.taskId) {
                url += `?taskId=${this.taskId}`;
            }
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.taskId = res.data.taskId;
                    this.taskInfo = res.data;
                    if(!['S', 'F', 'TP'].includes(this.taskInfo.status)){
                        this.initWebsocket();
                    }
                    else if(this.taskInfo.status === 'TP' && this.taskInfo.failureCode === 'TOKENS_LACK'){
                        this.isTokensLack = true;
                    }
                    if(this.isReset && this.isTokensLack) {
                        this.$message.error(this.$t('tools.tools_gptTranslate_page_method_initTaskList_message_1'));
                        this.isReset = false;
                    }
                }
            }).finally(()=>{

            })
        },
        initWebsocket(){
            if(this.socket === null){
                let socketUrl = `${location.protocol === 'https:'? 'wss' : 'ws'}://${location.host}${wsApi.imWebsocket}`;
                this.socket = new IWebSocket(socketUrl, this.handleWsMessage);
                this.socket.connect();
            }
            this.socket.send({
                messageType: 'CMD',
                contentType: 'JSON',
                commandCode: 'TOOLKIT_GPT_TRANS_LOGS',
                content: {
                    taskId: this.taskId,
                },
            });
        },
        handleWsMessage(data) {
            const commandCode = data.commandCode;
            switch (commandCode) {
                case 'TOOLKIT_GPT_TRANS_LOGS':
                    let arr = []
                    arr.push(data.content);
                    this.taskInfo['logs'].push(data.content);
                    // const node = document.querySelector('.current-task-logs .logs-content').lastElementChild;
                    // node.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'TOOLKIT_GPT_TRANS_REALTIME_STAGE':
                    this.taskInfo['current'] = data.content;
                    this.taskInfo['process'] = data.content.process;
                    break;
                case 'TOOLKIT_GPT_TRANS_COMPLETE':
                    this.taskInfo['status'] = data.content.status;
                    this.taskInfo['statusDesc'] = data.content.statusDesc;
                    this.taskInfo['process'] = data.content.process;
                    this.socket.send({
                        messageType: 'CMD',
                        contentType: 'JSON',
                        commandCode: 'TOOLKIT_GPT_TRANS_LOGS_CLEAN',
                        content: {
                            taskId: this.taskId,
                        },
                    });
                    this.socket.close();
                    this.socket = null;
                    break;
            }
        },
        
        createTask() {
            this.docs = this.$refs.uploadNode.getOssFiles();
            if (this.docs.length === 0) {
                this.$message.error(this.$t('tools.tools_gptTranslate_page_method_createTask_message_1'));
                return;
            }
            let url = toolsApi.createGPTTranslateTask;
            let postData = {
                sourceLang: this.form.source,
                targetLang: this.form.target,
                files: this.docs,
                termFiles: this.termFiles,
                memsFiles: this.memoryFiles,
            };
            this.btnLoading = true;
            this.$ajax.post(url, postData).then( async res => {
                if(res.status === 200){
                    // this.initTaskList();
                    this.taskId = res.data.taskId;
                    await tools.sleep(2000);
                    this.pollingTaskTokenInfo(res.data.taskId);
                    this.taskTaskTokenInfoTimer = setInterval(()=>{
                        this.pollingTaskTokenInfo(res.data.taskId);
                    }, 10 * 1000)
                }
            }).catch(e => {
                
            }).finally(()=>{
                this.btnLoading = false;
            });

        },
        pollingTaskTokenInfo(taskId){
            let url = `${toolsApi.pollingGPTTranslateTaskTokenInfo}?taskId=${taskId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    let data = res.data;
                    if (data.errorCode === 'PARSE_FAILED') {
                        this.$message.error(this.$t('tools.tools_gptTranslate_page_method_pollingTaskTokenInfo_message_1'));
                        this.btnLoading = false;
                        clearInterval(this.taskTaskTokenInfoTimer);
                        this.taskTaskTokenInfoTimer = null; 
                        return;                   
                    }
                    this.taskTokenInfo = data;
                    if(this.step === 1){
                        this.step = 2;
                        this.btnLoading = false;
                    }
                    if(data.parseComplete) {
                        clearInterval(this.taskTaskTokenInfoTimer);
                        this.taskTaskTokenInfoTimer = null;
                    }
                    
                }
            })
        },
        langSelectChangeEvent(val){
            if(val === this.form.target) {
                if(val === 'zh'){
                    this.form.target = 'en';
                }else {
                    this.form.target = 'zh';
                }
            }
        },
        closeUploadDocument() {
            this.docs = [];
        },
        handleDropdownEvent(command, item, index) {
            switch (command) {
                case 'target':
                case 'bilingual':
                // case 'term':
                // case 'qa':
                    this.downloadFile(item, index, command);
                    break;
                case 'delete':
                    this.deleteTask(item);
                    break;
            }
        },
        downloadFile(item, type) {
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                metadataIdList: [item.taskId], 
                title: item.name,
                metadataType: 'TOOLKIT_GT',
                moreOptions: {
                    exportFileType: type,
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
                
            })
        },
        deleteTask(item){
            this.taskId = item.taskId;
            this.showAlertDialog({
                message: this.$t('tools.tools_gptTranslate_page_method_deleteTask_message_1'),
                buttonType: 'danger',
            }, ()=>(this.deleteTaskEvent()));
        },
        deleteTaskEvent(){
            let url = `${toolsApi.deleteGPTTranslateTask}?ids=${this.taskId}`;
            this.$ajax.delete(url).then(res => {
                if(res.status === 200){
                    this.taskId = null;
                    this.taskInfo = null;
                    this.initTaskList();
                    this.$message.success(this.$t('tools.tools_gptTranslate_page_method_deleteTaskEvent_message_1'));
                }
            });
        },
        showAlertDialog(params, confirmEvent) {
            this.alertInfo = {...this.alertInfo, ...params, confirmEvent};
            this.$refs.alertDialogDom.setShow(true);
        },
        handleReset() {
            this.isReset = true;
            this.startTranslateTask();
        },
        
        handleUploadTermDialogClose() {

        },
        handleUploadMemoryDialogClose() {

        },
        saveUploadTermEvent() {
            this.termFiles = this.$refs.uploadTermDom.getOssFiles();
            this.uploadTermDialog.show = false;
            console.log(this.termFiles);
        },
        saveUploadMemoryEvent() {
            this.memoryFiles = this.$refs.uploadMemoryDom.getOssFiles();
            this.uploadMemoryDialog.show = false;
            console.log(this.memoryFiles);
        },
        downloadTemplateEvent(type) {
            window.open(
                type === 'excel' ? 
                    'https://nextcat-prod.oss-cn-beijing.aliyuncs.com/files/public/术语表模板.xlsx' : 
                    'https://nextcat-prod.oss-cn-beijing.aliyuncs.com/files/public/术语表模板.tbx'
            );
        },
        downloadMemoryTemplateEvent(type) {
            window.open(
                type === 'excel' ? 
                    'https://nextcat-prod.oss-cn-beijing.aliyuncs.com/files/public/记忆库模板.xlsx' : 
                    'https://nextcat-prod.oss-cn-beijing.aliyuncs.com/files/public/记忆库模板.tmx' 
            );
        }
    },
    mounted(){
        this.initTaskList();
    },
    beforeDestroy(){
        clearInterval(this.taskTimer);
        this.taskTimer = null;
    }
}
</script>
<style lang="scss" scoped>
.gpt-translate-index-page {
    position: relative;
    height: 100%;
    background-image: url("../../../../static/images/tools/video-translate-bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto;
    .gpt-translate-wapper {
        // min-height: 1000px;
        width: 900px;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.5);
        border: 0.669748px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(20.0924px);
        border-radius: 20px;
    }
    .gpt-translate-grid {
        width: 900px;
        margin: 0px auto;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        
            .poster {
                height: 124px;
                position: relative;
                .play-cover {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: #ffffff;
                    font-size: 30px;
                    background: rgba(0, 0, 0, 0.3);
                    border-radius: 10px 10px 0 0;
                    display: none;
                }
                &:hover {
                    .play-cover {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                ::v-deep .el-image{
                    width: 100%;
                    height: 100%;
                    border-radius: 10px 10px 0px 0px;
                }
            }
            .in-progress {
                color: #2979FF;
            }
            .fail {
                color: #F56C6C;
            }
            .success {
                color: #00B670;
            }
    }
    .gpt-translate-main{
        // min-height: 900px;
        width: 860px;
        margin: 20px auto;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 20px;
        .title-content{
            height: 52px;
            box-sizing: border-box;
            border-bottom: 1px solid #0000001A;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 600;
            img{
                width: 33px;
                height: 32px;
                margin-right: 8px;
            }
        }
        .gpt-translate-content {
            padding: 20px;
            ::v-deep .el-textarea {
                &.is-disabled .el-textarea__inner {
                    background-color: #ffffff;
                }
            }
            ::v-deep .el-upload-dragger {
                border-radius: 16px;
            }
            .condition-item {
                max-width: 130px;
                padding-right: 10px;
            }
            
            .condition-custom {
                ::v-deep .el-input {
                    .el-input__inner{
                        // height: 24px;
                        // font-size: 12px;
                        border-radius: 20px;
                        border-style: dashed;
                        padding-right: 45px;
                    }
                }
            }
            .model-wapper {
                margin-right: 10px;
                .model-list {
                    display: flex;
                    .item {
                        height: 26px;
                        margin-left: 10px;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 15px;
                        background-color: #ffffff;
                        border-radius: 4px;
                        box-sizing: border-box;
                        border: 1px solid #0000001A;
                        cursor: pointer;
                        &:first-child {
                            margin-left: 0;
                        }
                        &.active {
                            border-color: #1AC49C;
                            cursor: default;
                        }
                    }
                }
            }
            .upload-file-list {
                height: 153px;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0 10px;
                .file-item {
                    display: flex;
                    align-items: center;
                    padding: 10px 0;
                    box-sizing: border-box;
                    border-bottom: 1px solid #0000001A;
                    .name {
                        font-size: 14px;
                        color: #999999;
                        flex: 1;
                        white-space:nowrap;
                        text-overflow:ellipsis;
                        overflow:hidden;
                        margin-right: 10px;
                    }
                    .progress {
                        flex-shrink: 0;
                    }
                    .delete-btn {
                        flex-shrink: 0;
                        display: none;
                        margin-left: 10px;
                        font-size: 16px;
                        cursor: pointer;
                        color: #00000066;
                        &:hover{
                            color: #000;
                        }
                    }
                    &:hover{
                        .delete-btn {
                            display: block;
                        }
                    }
                }
            }
            .document-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                .file-type {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: #F8F8F8;
                    font-size: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .polish-status {
                    height: 24px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    background-color: #2979FF33;
                    color: #2979FF;
                    border-radius: 20px;
                    &.warning {
                        background-color: #FF990033;
                        color: #FF9900;
                    }
                    &.error {
                        background-color: #F56C6C33;
                        color: #F56C6C;
                    }
                    &.success {
                        background-color: #00B67033;
                        color: #00B670;
                    }
                    i{
                        margin-right: 4px;
                    }
                }
                .close-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #666666;
                    padding: 14px;
                    cursor: pointer;
                    &:hover {
                        color: #000000;
                    }
                }
            }
        }
        .result-title-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 52px;
            .left-content{
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 15px;
                img{
                    width: 33px;
                    height: 32px;
                    margin-right: 12px;
                }
            }
        }
        .result-content {
            margin-top: 10px;
            font-size: 14px;
            line-height: 22px;
            height: 162px;
            // max-height: 240px;
            overflow-y: auto;
            ::v-deep .diff-highlight {
                background-color: #D4E4FF;
                // font-weight: 600;
            }
            
        }
    }
    .wapper-block {
        background: linear-gradient(180deg, rgba(215, 245, 232, 0.6) 0%, rgba(215, 233, 245, 0) 100%);
        backdrop-filter: blur(20.0924px);
        border-radius: 20px;
        padding: 15px;
        .token-info {
            padding-bottom: 24px;
            .file-icon {
                display: inline-flex;
                width: 60px;
                height: 60px;
                border-radius: 50px;
                background-color: #F8F8F8;
                font-size: 25px;
                align-items: center;
                justify-content: center;
            }
            text-align: center;
        }
    }
    .style-label {
        padding: 0 10px;
        height: 32px;
        font-size: 13px;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            .name{
                color:#000;
            }
        }
        
        &.active {
            border-color: #00B670;
            .name{
                color: #00B670;
            }
        }
        .name {
            color: #999999;
        }
        .close {
            color: #999999;
            margin-left: 10px;
            font-size: 14px;
            &:hover{
                color: #000;
            }
        }
    }
    .text-input {
        margin-top: 10px;
        ::v-deep .el-textarea__inner{
            padding: 0;
            border: none;
        }
    }
    .copy-btn {
        width: 60px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        border-radius: 32px;
        font-size: 12px;
        cursor: pointer;
    }
    [disabled],:disabled{
        cursor: not-allowed;
        pointer-events: none;
        opacity: .5;
    }
    .send-btn {
        font-size: 20px;
        padding: 5px;
    }
    .divider-style {
        background-color: #0000001A;
        margin: 0;
    }
    .project-type {
        height: 36px;
        border-radius: 50px;
        box-sizing: border-box;
        border: 1px solid transparent;
        padding: 0 15px;
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        &.current {
            background-color: #E5E5E5;
            border-color: #E5E5E5;
        }
        &.history {
            position: absolute;
            top: 5px; 
            right: 0px; 
            background-color:#000;
            border-color: #DDDDDD;
            color: #fff;
            cursor: pointer;
        }
    }
    .current-task-info {
        display: flex;
        align-items: center;
    }
    .current-task-steps {
        ::v-deep .el-steps {
            .el-step__head {
                .el-step__line {
                    height: 1px;
                    background-color: #999999;
                }
                .el-step__icon {
                    &.is-text {
                        border-width: 1px;
                        border-color: #999999;
                    }
                }
                .el-step__icon-inner {
                    font-size: 14px;
                    color: #999999;
                    font-weight: normal
                }
                &.is-process {
                    .el-step__line {
                        background-color: #00D959;
                    }
                    .el-step__icon {
                        &.is-text {
                            border-color: #00D959;
                            background-color: #00B670;
                        }
                    }
                    .el-step__icon-inner {
                        color: #fff;
                    }
                }
                &.is-finish {
                    border-color: transparent;
                }
                
            }
            .el-step__title {
                font-size: 14px;
                color: #999999;
                &.is-process {
                    color: #333;
                    font-weight: normal;
                }
                
            }
        }
    }
    .current-task-logs {
        $padding: 25px;
        width: 900px - 2 * $padding;
        margin: 25px auto 0 auto;
        padding: $padding;
        background-color: #ffffff;
            border-radius: 20px;
        .logs-content {
            height: 240px;
            overflow: auto;
            p {
                font-size: 14px;
                line-height: 1.75;
                color: #999999;
                &:last-child {
                    color: #00B670;
                }
            }
        }
    }
}
</style>
